import React, { useState } from "react";
import "./SpendingAllowance.scss";
import WsappBlue from "../../images/wsapp-blue.svg";
import TaskNote from "../../images/task-note.svg";
import VeggieBag from "../../images/veggie-bag.svg";
import RobotFigure from "../../images/robot.svg";
import Cart from "../../images/cart.svg";
import WhiteCall from "../../images/orange-call-icon.svg";
const SpendingAllowance = () => {
  const [step, setStep] = useState(0);
  const [showParagraph, setShowParagraph] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [spanText, setSpanText] = useState(
    "Checking Eligibility. Please standby...."
  );

  const handleStart = () => {
    setStep(1);

    setTimeout(() => {
      setShowParagraph(false);
      setTimeout(() => {
        setShowParagraph(true);
      }, 0);
    }, 0);
    setTimeout(() => {
      setShowButtons(true);
    }, 800);
  };
  const handleMedicareAnswer = () => {
    setStep(2);

    setShowParagraph(false);
    setShowButtons(false);
    setTimeout(() => {
      setSpanText("");

      setShowParagraph(true);
    }, 1000);
    setTimeout(() => {
      setShowButtons(true);
    }, 1000);
  };

  const handleVeteranAnswer = () => {
    setStep(3);
    setShowParagraph(false);
    setShowButtons(false);
    setSpanText(
      "Great! Thank you for your service. Please wait while we confirm your eligibility…"
    );
    setTimeout(() => {
      setShowParagraph(true);
      setSpanText("");
    }, 1200);
    setTimeout(() => {
      setShowButtons(true);
    }, 1400);
  };
  return (
    <section className="spending-allowance">
      <div className="container">
        <div className="spending-allowance__header text-with-border">
          <div className="text-with-border__content">
            <h1 className="text-with-border__title">
              Get Your Spending Allowance Benefit In 3 Simple Steps
            </h1>
            <p className="text-with-border__subtitle">
              We go out of our way to make the whole process easy and
              hassle-free
            </p>
          </div>
        </div>

        <div className="spending-allowance__details">
          <div className="spending-allowance__wrap">
            <div className="spending-allowance__sub">
              <div className="tut-icon">
                <figure>
                  <img src={WsappBlue} alt="" />
                </figure>
              </div>
              <div className="text">
                <h3>Call Us Now</h3>
                <p>
                  Speak with a friendly licensed sales agent who will review
                  your eligibility and answer your questions.
                </p>
              </div>
              <div className="step">Step 1</div>
            </div>
            <div className="spending-allowance__sub">
              <div className="tut-icon">
                <figure>
                  <img src={TaskNote} alt="" />
                </figure>
              </div>
              <div className="text">
                <h3>Compare Plans</h3>
                <p>
                  Our knowledgeable licensed insurance agents will help you
                  compare plans to find the right fit for you.
                </p>
              </div>
              <div className="step">Step 2</div>
            </div>
            <div className="spending-allowance__sub">
              <div className="tut-icon">
                <figure>
                  <img src={VeggieBag} alt="" />
                </figure>
              </div>
              <div className="text">
                <h3>Get Your Spending Allowance</h3>
                <p>
                  Enroll in your chosen plan and start enjoying your spending
                  allowance benefit.
                </p>
              </div>
              <div className="step">Step 3</div>
            </div>
          </div>
          <div className="spending-allowance__orange">
            <div className="spending-allowance__card-main">
              <div
                className={`text-card ${step === 3 ? "text-top" : "text-card"}`}
              >
                <figure>
                  <img src={RobotFigure} alt="" />
                </figure>

                {step === 2 && spanText && <span>{spanText}</span>}
                {step === 3 && spanText && <span>{spanText}</span>}
                {step === 0 && (
                  <p>
                    Hi, Our Website is Free of charge and there is no obligation
                    to enroll!
                  </p>
                )}
                {step === 1 && showParagraph && (
                  <p>
                    First question, Do you currently have both Medicare Part A
                    and Part B or will you be getting Medicare in the next 3
                    months?
                  </p>
                )}
                {step === 2 && showParagraph && (
                  <p>
                    Thank you! Next question, did you serve in the Active
                    Military, Naval, or Air Service of the United States?
                  </p>
                )}
                {step === 3 && showParagraph && (
                  <div>
                    <div>
                      <p>
                        It sounds like you may qualify today! In order to
                        complete your enrollment, we need to speak with you.
                        Remember, all information is 100% Free of Charge and
                        there is no obligation to enroll.
                      </p>
                    </div>
                    <div className="mt-txt">
                      <p>
                        🟢 A Licensed Insurance Agent is Available.
                        <br />
                        🕒 M-F 8AM-6PM CT TTY:711
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className={`${step === 3 ? "cta-top" : "cta-button "}`}>
                {step === 0 && (
                  <button onClick={handleStart}>Click Here To Start</button>
                )}
                {step === 1 && showButtons && (
                  <div>
                    <button
                      onClick={handleMedicareAnswer}
                      className="margin-button"
                    >
                      Yes
                    </button>
                    <button
                      onClick={handleMedicareAnswer}
                      className="margin-button"
                    >
                      No
                    </button>
                    <button
                      onClick={handleMedicareAnswer}
                      className="margin-button"
                    >
                      Skip
                    </button>
                  </div>
                )}
                {step === 2 && showButtons && (
                  <div>
                    <button
                      onClick={handleVeteranAnswer}
                      className="margin-button"
                    >
                      Yes
                    </button>
                    <button
                      onClick={handleVeteranAnswer}
                      className="margin-button"
                    >
                      No
                    </button>
                    <button
                      onClick={handleVeteranAnswer}
                      className="margin-button"
                    >
                      Skip
                    </button>
                  </div>
                )}
                {step === 3 && showButtons && (
                  <button className="margin-button">
                    <a href="tel:6408886518">
                      <figure>
                        <img src={WhiteCall} alt="" />
                      </figure>
                      Call Toll Free (640) 888-6518
                    </a>
                  </button>
                )}
              </div>

              <figure className="cart">
                <img src={Cart} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpendingAllowance;
